/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef } from "react"
import { withRouter } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { IMAGE } from "@/assets"
import "./styles.less"

const MContainer = ({
  className,
  render
}) => {

  return (
    <div className={`m_container ${!!className ? className : ""}`}>
      <div className="m_container_content">
      {
        render()
      }
      </div>
    </div>
  )
}

const IndexPage = ({ history }) => {
  const { t, i18n } = useTranslation()
  const timeoutRef = useRef(null)
  const [currentIndex, setCurrentIndex] = useState(8)
  const [isTransitioning, setIsTransitioning] = useState(false)
  const [lastClickTime, setLastClickTime] = useState(0)
  const [screenWidth, setScreenWidth] = useState(window.innerWidth)
  const [session7ItemW, setSession7ItemW] = useState(0)
  const [session7ItemS, setSession7ItemS] = useState(0)

  const list = [{
    image: IMAGE.m_session7_1,
    label: t("Freelance Marketplaces")
  }, {
    image: IMAGE.m_session7_2,
    label: t("Online Advertising & Marketing Services")
  }, {
    image: IMAGE.m_session7_3,
    label: t("Gaming & Esports")
  }, {
    image: IMAGE.m_session7_4,
    label: t("E-commerce")
  }, {
    image: IMAGE.m_session7_5,
    label: t("Cloud Services & SaaS Platforms")
  }, {
    image: IMAGE.m_session7_6,
    label: t("Dating & Social Platform")
  }, {
    image: IMAGE.m_session7_7,
    label: t("Live Streaming & Content Creation")
  }, {
    image: IMAGE.m_session7_8,
    label: t("Financial Services & Forex")
  }]

  const SESSION7LIST = list.concat(list).concat(list)

  const resetTimeout = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current)
    }
  }

  useEffect(() => {
    if (currentIndex < list.length) {
      setIsTransitioning(false)
      setCurrentIndex(currentIndex + list.length + 1)
      setTimeout(() => {
        setIsTransitioning(true)
      }, 500)
    } else if (currentIndex > list.length * 2) {
      setIsTransitioning(false)
      setCurrentIndex(currentIndex - list.length - 1)
      setTimeout(() => {
        setIsTransitioning(true)
      }, 500)
    } else {
      resetTimeout()
      timeoutRef.current = setTimeout(() => {
        setIsTransitioning(true)
        setCurrentIndex(currentIndex + 1)
      }, 3000)
    }
    return () => {
      resetTimeout()
    }
  }, [currentIndex])

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth)
    }
    window.addEventListener("resize", handleResize)
    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [])

  useEffect(() => {
    let width
    if (screenWidth >= 1440) {
      width = (1440 - 160) / 4 - 24
      setSession7ItemS(24)
    } else if (screenWidth > 800) {
      width = (screenWidth - 160) / 4 - 24
      setSession7ItemS(24)
    } else {
      width = screenWidth / 2 - 16
      setSession7ItemS(16)
    }
    setSession7ItemW(width)
  }, [screenWidth])

  return (
    <div className="root-page">
      <MContainer
        className="m_header_container"
        render={() => (
          <div className="m_header">
            <img src={IMAGE.headLogo} alt="" draggable="false" className="logo" />
            <div className="buttons">
              <button className="lang" onClick={() => {
                if (i18n.language.startsWith("zh")) {
                  i18n.changeLanguage("en")
                } else {
                  i18n.changeLanguage("zh")
                }
              }}>
                <img src={IMAGE.lang2} alt="" draggable="false" />
                <p>{ t("language") }</p>
              </button>
              <button className="start" onClick={() => window.open("https://console.satogate.io/")}>
                <p>{ t("get.started") }</p>
              </button>
            </div>
          </div>
        )}
      />
      <MContainer
        className="m_session1_container"
        render={() => (
          <div className="m_session1">
            <p className="m_session1_title">{ t("Empower Your Business with Crypto Wallet Services") }</p>
            <p className="m_session1_content">{ t("A comprehensive solution for businesses to manage and facilitate crypto transactions.") }</p>
            <button onClick={() => window.open("https://console.satogate.io/")}>{ t("get.started") }</button>
          </div>
        )}
      />
      <MContainer
        render={() => (
          <div className="m_session2">
            <div className="m_session2_item">
              <img src={IMAGE.m_session2_1} alt="" draggable="false" />
              <p>{t("Easy Setup")}</p>
            </div>
            <div className="m_session2_item">
              <img src={IMAGE.m_session2_2} alt="" draggable="false" />
              <p>{t("Secure Transactions")}</p>
            </div>
            <div className="m_session2_item">
              <img src={IMAGE.m_session2_3} alt="" draggable="false" />
              <p>{t("Flexible Asset Management")}</p>
            </div>
          </div>
        )}
      />
      <MContainer
        render={() => (
          <div className="m_session3">
            <p className="m_session3_title">{ t("What We Offer") }</p>
            <p className="m_session3_content">{ t("With an array of robust and adaptable services, we cater to the diverse needs of businesses in the digital age. Experience streamlined crypto transactions and management with Satogate.") }</p>
          </div>
        )}
      />
      <MContainer
        render={() => (
          <div className="m_session4">
            <div className="m_session4_div">
              <p className="m_session4_title">{ t("Wallet-as-a-Service") }</p>
              <p className="m_session4_content">{ t("Empower businesses with seamless crypto asset management.") }</p>
              <div className="m_session4_item">
                <img src={IMAGE.m_session4_2} alt="" draggable="false" />
                <p>{ t("Built on an Account Abstraction-based technical architecture.") }</p>
              </div>
              <div className="m_session4_item">
                <img src={IMAGE.m_session4_2} alt="" draggable="false" />
                <p>{ t("Full asset control, Satogate does not hold private keys.") }</p>
              </div>
              <div className="m_session4_item">
                <img src={IMAGE.m_session4_2} alt="" draggable="false" />
                <p>{ t("Multi-role authorization and workflow management.") }</p>
              </div>
              <div className="m_session4_item">
                <img src={IMAGE.m_session4_2} alt="" draggable="false" />
                <p>{ t("All customer assets are segregated using unique digital addresses.") }</p>
              </div>
              <div className="m_session4_item">
                <img src={IMAGE.m_session4_2} alt="" draggable="false" />
                <p>{ t("KYT verification processes.") }</p>
              </div>
            </div>
            <img src={IMAGE.m_session4_1} alt="" draggable="false" className="m_session4_image" />
          </div>
        )}
      />
      <MContainer
        render={() => (
          <div className="m_session4" style={{ "--direction": "column-reverse" }}>
            <img src={IMAGE.m_session4_3} alt="" draggable="false" className="m_session4_image" />
            <div className="m_session4_div">
              <p className="m_session4_title">{ t("On/Off-Ramp") }</p>
              <p className="m_session4_content">{ t("Facilitate easy and compliant transition between fiat (mainly USD) and crypto (predominantly stablecoins).") }</p>
              <div className="m_session4_item">
                <img src={IMAGE.m_session4_2} alt="" draggable="false" />
                <p>{ t("Partnering with licensed MSOs to facilitate cryptocurrency exchanges.") }</p>
              </div>
              <div className="m_session4_item">
                <img src={IMAGE.m_session4_2} alt="" draggable="false" />
                <p>{ t("Collaborating with licensed card acquirers and local payment companies.") }</p>
              </div>
              <div className="m_session4_item">
                <img src={IMAGE.m_session4_2} alt="" draggable="false" />
                <p>{ t("KYB verification processes.") }</p>
              </div>
            </div>
          </div>
        )}
      />
      <MContainer
        render={() => (
          <div className="m_session4">
            <div className="m_session4_div">
              <p className="m_session4_title">{ t("Pay-in Services") }</p>
              <p className="m_session4_content">{ t("Aid merchants in generating crypto collection addresses and handling incoming crypto payments.") }</p>
              <div className="m_session4_item">
                <img src={IMAGE.m_session4_2} alt="" draggable="false" />
                <p>{ t("Supports multi-chain, multi-currency transactions.") }</p>
              </div>
              <div className="m_session4_item">
                <img src={IMAGE.m_session4_2} alt="" draggable="false" />
                <p>{ t("Real-time conversion to stablecoin reduces volatility risk.") }</p>
              </div>
              <div className="m_session4_item">
                <img src={IMAGE.m_session4_2} alt="" draggable="false" />
                <p>{ t("Instant settlement.") }</p>
              </div>
              <div className="m_session4_item">
                <img src={IMAGE.m_session4_2} alt="" draggable="false" />
                <p>{ t("Flexible integration paths API or H5 interface.") }</p>
              </div>
            </div>
            <img src={IMAGE.m_session4_4} alt="" draggable="false" className="m_session4_image" />
          </div>
        )}
      />
      <MContainer
        render={() => (
          <div className="m_session4" style={{ "--direction": "column-reverse" }}>
            <img src={IMAGE.m_session4_5} alt="" draggable="false" className="m_session4_image" />
            <div className="m_session4_div">
              <p className="m_session4_title">{ t("Pay-out Services") }</p>
              <p className="m_session4_content">{ t("Utilizing cryptocurrency enables convenient and cost-effective payments to global suppliers, partners, and employees.") }</p>
              <div className="m_session4_item">
                <img src={IMAGE.m_session4_2} alt="" draggable="false" />
                <p>{ t("Bill Pay") }</p>
              </div>
              <div className="m_session4_item">
                <img src={IMAGE.m_session4_2} alt="" draggable="false" />
                <p>{ t("Batch Pay") }</p>
              </div>
              <div className="m_session4_item">
                <img src={IMAGE.m_session4_2} alt="" draggable="false" />
                <p>{ t("Pay the blockchain fee with USDT.") }</p>
              </div>
            </div>
          </div>
        )}
      />
      <MContainer
        render={() => (
          <div className="m_session5">
            <p className="m_session5_title">{ t("Business Credit Card") }</p>
            <p className="m_session5_content">{ t("Empower your team with physical and virtual company credit cards funded by crypto. However big or small your team is, our cards have got you covered.") }</p>
            <button onClick={() => window.open("mailto:contact@satogate.io")}>{ t("Contact Sales") }</button>
            <img src={IMAGE.m_session5_1} alt="" draggable="false" />
          </div>
        )}
      />
      <MContainer
        className="m_session6_container"
        render={() => (
          <div className="m_session6">
            <img src={IMAGE.m_session6_1} alt="" draggable="false" className="m_session6_image" />
            <div className="m_session6_div">
              <p className="m_session6_title">{ t("Full API Integration") }</p>
              <p className="m_session6_content">{ t("Our Full API Integration delivers seamless interoperability for your business needs. With Satogate, you can easily integrate crypto transactions into your existing systems, facilitating a smooth and efficient process. From transaction tracking to instant notifications, our comprehensive API offers all the functionality required for managing crypto assets effectively. This empowers your business to leverage the full potential of cryptocurrencies while ensuring optimal security and performance.") }</p>
              <div className="m_session6_list">
                <div className="m_session6_item">
                  <img src={IMAGE.m_session6_2} alt="" draggable="false" />
                  <p>{ t("Developer-friendly API") }</p>
                </div>
                <div className="m_session6_item">
                  <img src={IMAGE.m_session6_3} alt="" draggable="false" />
                  <p>{ t("Comprehensive Documentation") }</p>
                </div>
                <div className="m_session6_item">
                  <img src={IMAGE.m_session6_4} alt="" draggable="false" />
                  <p>{ t("Security-centric Approach") }</p>
                </div>
              </div>
            </div>
          </div>
        )}
      />
      <MContainer
        render={() => (
          <div className="m_session7">
            <p className="m_session7_title">{ t("INDUSTRIES WE SERVE") }</p>
            <p className="m_session7_content">{ t("Join the growing sphere of digital assets and enable your business with innovative crypto wallet services. Perfect for businesses across various industries, our solution can be easily integrated into your existing operations. Manage your crypto assets, accept cross-border transactions, and revolutionize your payment system today!") }</p>
            <div className="m_session7_carousel">
              <div className="m_session7_list" 
                style={{
                  "--l": currentIndex * (session7ItemW + session7ItemS) + session7ItemW * 0.5,
                  "--g": `${session7ItemS}px`,
                  transition: isTransitioning ? "transform 0.5s ease-in-out" : "none"
                }}
              >
              {
                SESSION7LIST.map((item, index) => {
                  const scaleIndex = screenWidth > 800 ? currentIndex + 2 : currentIndex + 1
                  return (
                    <div key={index} className="m_session7_item" 
                      style={{ 
                        "--w": `${session7ItemW}px`, 
                        "--scale": scaleIndex === index ? 1.05 : 0.95,
                        transition: isTransitioning ? "transform 0.5s ease-in-out" : "none"
                      }}
                    >
                      <img src={item.image} alt="" draggable="false" />
                      <p>{ item.label }</p>
                    </div>
                  )
                })
              }
              </div>
            </div>
            <div className="m_session7_dot">
              <img src={IMAGE.m_session7_arrow_left} alt="" draggable="false" onClick={() => {
                if (!isTransitioning) return
                const now = Date.now()
                if (now - lastClickTime < 1000) return
                setLastClickTime(now)
                setCurrentIndex(currentIndex - 1)
              }} />
              <div className="m_session7_process">
                <div className="m_session7_process_active" 
                  style={{ 
                    "--w": `${Math.abs(currentIndex -  list.length) * 100 / list.length}%`,
                    transition: isTransitioning ? "width 0.5s ease-in-out" : "none"
                  }}
                />
              </div>
              <img src={IMAGE.m_session7_arrow_right} alt="" draggable="false" onClick={() => {
                if (!isTransitioning) return
                const now = Date.now()
                if (now - lastClickTime < 1000) return
                setLastClickTime(now)
                setCurrentIndex(currentIndex + 1)
              }} />
            </div>
          </div>
        )}
      />
      <MContainer
        render={() => (
          <div className="m_session8">
            <p className="m_session8_title">{ t("TRUSTED BY TOP BRANDS") }</p>
            <p className="m_session8_content">{ t("Top enterprises, startups, and e-commerce platforms worldwide rely on our robust crypto wallet services for their digital assets management needs. Join them in harnessing the power of Satogate.") }</p>
            <div className="m_session8_list">
            {
              Array.from({length: 56}, (_, i) => i + 1).map((item, index) => {
                return <img src={IMAGE[`m_session8_${item}`]} alt="" draggable="false" key={index} />
              })
            }
            </div>
            <div className="m_session8_carousel">
              <img src={IMAGE.m_session8__1} alt="" draggable="false" className="m_session8_image" />
              <div className="m_session8_div">
                <div className="m_session8_div_head">
                  <img src={IMAGE.m_session8__2} alt="" draggable="false" className="m_session8_div_logo" />
                  <div className="m_session8_div_label">
                    <p className="m_session8_div_name">{ t("MXKPay") }</p>
                    <p className="m_session8_div_content">{ t("Leader in the expense management industry") }</p>
                  </div>
                </div>
                <p className="m_session8_div_desc">{ t("We utilize Satogate for instant cryptocurrency deposits to our accounts, Our cryptocurrency-related transaction volume has seen continuous growth.") }</p>
              </div>
            </div>
          </div>
        )}
      />
      <MContainer
        className="m_session9_container"
        render={() => (
          <div className="m_session9">
            <p className="m_session9_title">{ t("FLEXIBLE PRICING FOR EVERY BUSINESS SIZE") }</p>
            <img src={screenWidth > 800 ? IMAGE.m_session9_1 : IMAGE.m_session9_2} alt="" draggable="false" className="m_session9_image" />
          </div>
        )}
      />
      <MContainer
        className="m_session10_container"
        render={() => (
          <div className="m_session10">
            <p className="m_session10_title">{ t("JOIN US TODAY AND TAP INTO THE POWER OF CRYPTOCURRENCY TO ELEVATE YOUR BUSINESS!") }</p>
            <button onClick={() => window.open("https://console.satogate.io/")}>
              <p>{ t("Sign Up In Minutes") }</p>
            </button>
          </div>
        )}
      />
      <MContainer
        className="m_footer_container"
        render={() => (
          <div className="m_footer">
            <div className="m_footer_content">
              <img src={IMAGE.headLogo} alt="" draggable="false" className="m_footer_logo" />
              <p className="m_footer_t1">{ t("Satogate is an innovative platform specializing in crypto wallet services for businesses. As a proud subsidiary of Futures Lab, located in Hong Kong Science Park, our commitment lies in delivering top-tier, blockchain-based financial solutions.") }</p>
              <div className="m_footer_email" onClick={() => window.open("mailto:contact@satogate.io")}>
                <img src={IMAGE.footEmail} alt="" draggable="false" className="m_footer_email_image1" />
                <p>{ t("Email") }</p>
                <img src={IMAGE.arrow} alt="" draggable="false" className="m_footer_email_image2" />
              </div>
            </div>
            <p className="m_footer_t2">{ t("Copyright © 2023 All Rights Reserved By Futures Lab") }</p>
          </div>
        )}
      />
    </div>
  )
}
export default withRouter(IndexPage)
import lang2 from "./assets/lang2.png"
import headLogo from "./assets/logo.png"
import m_session2_1 from "./assets/m_session2_1.png"
import m_session2_2 from "./assets/m_session2_2.png"
import m_session2_3 from "./assets/m_session2_3.png"
import m_session4_1 from "./assets/m_session4_1.png"
import m_session4_2 from "./assets/m_session4_2.png"
import m_session4_3 from "./assets/m_session4_3.png"
import m_session4_4 from "./assets/m_session4_4.png"
import m_session4_5 from "./assets/m_session4_5.png"
import m_session5_1 from "./assets/m_session5_1.png"
import m_session6_1 from "./assets/m_session6_1.png"
import m_session6_2 from "./assets/m_session6_2.png"
import m_session6_3 from "./assets/m_session6_3.png"
import m_session6_4 from "./assets/m_session6_4.png"
import m_session7_1 from "./assets/m_session7_1.png"
import m_session7_2 from "./assets/m_session7_2.png"
import m_session7_3 from "./assets/m_session7_3.png"
import m_session7_4 from "./assets/m_session7_4.png"
import m_session7_5 from "./assets/m_session7_5.png"
import m_session7_6 from "./assets/m_session7_6.png"
import m_session7_7 from "./assets/m_session7_7.png"
import m_session7_8 from "./assets/m_session7_8.png"
import m_session7_arrow_left from "./assets/m_session7_arrow_left.png"
import m_session7_arrow_right from "./assets/m_session7_arrow_right.png"
import m_session8_1 from "./assets/m_session8_1.png"
import m_session8_2 from "./assets/m_session8_2.png"
import m_session8_3 from "./assets/m_session8_3.png"
import m_session8_4 from "./assets/m_session8_4.png"
import m_session8_5 from "./assets/m_session8_5.png"
import m_session8_6 from "./assets/m_session8_6.png"
import m_session8_7 from "./assets/m_session8_7.png"
import m_session8_8 from "./assets/m_session8_8.png"
import m_session8_9 from "./assets/m_session8_9.png"
import m_session8_10 from "./assets/m_session8_10.png"
import m_session8_11 from "./assets/m_session8_11.png"
import m_session8_12 from "./assets/m_session8_12.png"
import m_session8_13 from "./assets/m_session8_13.png"
import m_session8_14 from "./assets/m_session8_14.png"
import m_session8_15 from "./assets/m_session8_15.png"
import m_session8_16 from "./assets/m_session8_16.png"
import m_session8_17 from "./assets/m_session8_17.png"
import m_session8_18 from "./assets/m_session8_18.png"
import m_session8_19 from "./assets/m_session8_19.png"
import m_session8_20 from "./assets/m_session8_20.png"
import m_session8_21 from "./assets/m_session8_21.png"
import m_session8_22 from "./assets/m_session8_22.png"
import m_session8_23 from "./assets/m_session8_23.png"
import m_session8_24 from "./assets/m_session8_24.png"
import m_session8_25 from "./assets/m_session8_25.png"
import m_session8_26 from "./assets/m_session8_26.png"
import m_session8_27 from "./assets/m_session8_27.png"
import m_session8_28 from "./assets/m_session8_28.png"
import m_session8_29 from "./assets/m_session8_29.png"
import m_session8_30 from "./assets/m_session8_30.png"
import m_session8_31 from "./assets/m_session8_31.png"
import m_session8_32 from "./assets/m_session8_32.png"
import m_session8_33 from "./assets/m_session8_33.png"
import m_session8_34 from "./assets/m_session8_34.png"
import m_session8_35 from "./assets/m_session8_35.png"
import m_session8_36 from "./assets/m_session8_36.png"
import m_session8_37 from "./assets/m_session8_37.png"
import m_session8_38 from "./assets/m_session8_38.png"
import m_session8_39 from "./assets/m_session8_39.png"
import m_session8_40 from "./assets/m_session8_40.png"
import m_session8_41 from "./assets/m_session8_41.png"
import m_session8_42 from "./assets/m_session8_42.png"
import m_session8_43 from "./assets/m_session8_43.png"
import m_session8_44 from "./assets/m_session8_44.png"
import m_session8_45 from "./assets/m_session8_45.png"
import m_session8_46 from "./assets/m_session8_46.png"
import m_session8_47 from "./assets/m_session8_47.png"
import m_session8_48 from "./assets/m_session8_48.png"
import m_session8_49 from "./assets/m_session8_49.png"
import m_session8_50 from "./assets/m_session8_50.png"
import m_session8_51 from "./assets/m_session8_51.png"
import m_session8_52 from "./assets/m_session8_52.png"
import m_session8_53 from "./assets/m_session8_53.png"
import m_session8_54 from "./assets/m_session8_54.png"
import m_session8_55 from "./assets/m_session8_55.png"
import m_session8_56 from "./assets/m_session8_56.png"
import m_session8__1 from "./assets/m_session8__1.png"
import m_session8__2 from "./assets/m_session8__2.png"
import m_session9_1 from "./assets/m_session9_1.png"
import m_session9_2 from "./assets/m_session9_2.png"
import footEmail from "./assets/email.png"
import arrow from "./assets/arrow.png"

const BASEURL = "https://satogate.s3.amazonaws.com/website"

const head_logo = `${BASEURL}/head-logo.svg`
const foot_logo = `${BASEURL}/foot-logo.svg`
const close = `${BASEURL}/close.svg`
const BackWhite = `${BASEURL}/back-white.svg`
const LogoWhite = `${BASEURL}/logo-white.svg`
const SelectIcon = `${BASEURL}/select-icon.svg`
const Token = `${BASEURL}/token.svg`
const Network = `${BASEURL}/network.svg`
const SelectTag = `${BASEURL}/select-tag.svg`
const medium = `${BASEURL}/medium.svg`
const twitter = `${BASEURL}/twitter.svg`
const email = `${BASEURL}/email.svg`
const lang = `${BASEURL}/lang.svg`
const pushIcon = `${BASEURL}/push.svg`
const session1_image_1 = `${BASEURL}/session1/image-1.png`
const session1_icon_1 = `${BASEURL}/session1/icon-1.svg`
const session1_icon_2 = `${BASEURL}/session1/icon-2.svg`
const session1_icon_3 = `${BASEURL}/session1/icon-3.svg`
const session2_image_1 = `${BASEURL}/session2/image-1.png`
const session2_image_2 = `${BASEURL}/session2/image-2.png`
const session2_image_3 = `${BASEURL}/session2/image-3.png`
const session2_image_4 = `${BASEURL}/session2/image-4.png`
const session2_image_5 = `${BASEURL}/session2/image-5.png`
const session2_image_6 = `${BASEURL}/session2/image-6.png`
const session2_image_7 = `${BASEURL}/session2/image-7.png`
const session2_image_8 = `${BASEURL}/session2/image-8.png`
const session3_image_1 = `${BASEURL}/session3/image-1.png`
const session3_image_2 = `${BASEURL}/session3/image-2.png`
const session3_image_3 = `${BASEURL}/session3/image-3.png`
const session3_image_4 = `${BASEURL}/session3/image-4.png`
const session4_image_3 = `${BASEURL}/session4/image-3.svg`
const session4_image_4 = `${BASEURL}/session4/image-4.svg`
const session4_icon_1 = `${BASEURL}/session4/icon-1.svg`
const session4_icon_2 = `${BASEURL}/session4/icon-2.svg`
const session4_icon_3 = `${BASEURL}/session4/icon-3.svg`
const session7_image_1 = `${BASEURL}/session7/image-1.svg`
const session9_icon_1 = `${BASEURL}/session9/1.svg`
const session9_icon_2 = `${BASEURL}/session9/2.svg`
const session9_icon_3 = `${BASEURL}/session9/3.svg`
const session9_icon_4 = `${BASEURL}/session9/4.svg`
const session9_icon_5 = `${BASEURL}/session9/5.svg`
const session9_icon_6 = `${BASEURL}/session9/6.svg`
const session9_icon_7 = `${BASEURL}/session9/7.svg`
const session9_icon_8 = `${BASEURL}/session9/8.svg`
const session9_icon_9 = `${BASEURL}/session9/9.svg`
const session9_icon_10 = `${BASEURL}/session9/10.svg`
const session9_icon_11 = `${BASEURL}/session9/11.svg`
const session9_icon_12 = `${BASEURL}/session9/12.svg`
const session9_icon_13 = `${BASEURL}/session9/13.svg`
const session9_icon_14 = `${BASEURL}/session9/14.svg`
const session9_icon_15 = `${BASEURL}/session9/15.svg`
const session9_icon_16 = `${BASEURL}/session9/16.svg`
const session9_icon_17 = `${BASEURL}/session9/17.svg`
const session9_icon_18 = `${BASEURL}/session9/18.svg`
const session9_icon_19 = `${BASEURL}/session9/19.svg`
const session9_icon_20 = `${BASEURL}/session9/20.svg`
const session9_icon_21 = `${BASEURL}/session9/21.svg`
const session9_icon_22 = `${BASEURL}/session9/22.svg`
const session9_icon_23 = `${BASEURL}/session9/23.svg`
const session9_icon_24 = `${BASEURL}/session9/24.svg`
const session9_icon_25 = `${BASEURL}/session9/25.svg`
const session9_icon_26 = `${BASEURL}/session9/26.svg`
const session9_icon_27 = `${BASEURL}/session9/27.svg`
const session9_icon_28 = `${BASEURL}/session9/28.svg`
const session9_icon_29 = `${BASEURL}/session9/29.svg`
const session9_icon_30 = `${BASEURL}/session9/30.svg`
const session9_icon_31 = `${BASEURL}/session9/31.svg`
const session9_icon_32 = `${BASEURL}/session9/32.svg`
const session9_icon_33 = `${BASEURL}/session9/33.svg`
const session9_icon_34 = `${BASEURL}/session9/34.svg`
const session9_icon_35 = `${BASEURL}/session9/35.svg`
const session9_icon_36 = `${BASEURL}/session9/36.svg`
const session9_icon_37 = `${BASEURL}/session9/37.svg`
const session9_icon_38 = `${BASEURL}/session9/38.svg`
const session9_icon_39 = `${BASEURL}/session9/39.svg`
const session9_icon_40 = `${BASEURL}/session9/40.svg`
const session9_icon_41 = `${BASEURL}/session9/41.svg`
const session9_icon_42 = `${BASEURL}/session9/42.svg`
const session9_icon_43 = `${BASEURL}/session9/43.svg`
const session9_icon_44 = `${BASEURL}/session9/44.svg`
const session9_icon_45 = `${BASEURL}/session9/45.svg`
const session9_icon_46 = `${BASEURL}/session9/46.svg`
const session9_icon_47 = `${BASEURL}/session9/47.svg`
const session9_icon_48 = `${BASEURL}/session9/48.svg`
const session9_icon_49 = `${BASEURL}/session9/49.svg`

const IMAGE = {
  lang2,
  m_session2_1, m_session2_2, m_session2_3,
  m_session4_1, m_session4_2, m_session4_3, m_session4_4, m_session4_5,
  m_session5_1,
  m_session6_1, m_session6_2, m_session6_3, m_session6_4,
  m_session7_1, m_session7_2, m_session7_3, m_session7_4, m_session7_5, m_session7_6, m_session7_7, m_session7_8, m_session7_arrow_left, m_session7_arrow_right,
  m_session8_1, m_session8_2, m_session8_3, m_session8_4, m_session8_5, m_session8_6, m_session8_7, m_session8_8, m_session8_9, m_session8_10,
  m_session8_11, m_session8_12, m_session8_13, m_session8_14, m_session8_15, m_session8_16, m_session8_17, m_session8_18, m_session8_19, m_session8_20,
  m_session8_21, m_session8_22, m_session8_23, m_session8_24, m_session8_25, m_session8_26, m_session8_27, m_session8_28, m_session8_29, m_session8_30,
  m_session8_31, m_session8_32, m_session8_33, m_session8_34, m_session8_35, m_session8_36, m_session8_37, m_session8_38, m_session8_39, m_session8_40,
  m_session8_41, m_session8_42, m_session8_43, m_session8_44, m_session8_45, m_session8_46, m_session8_47, m_session8_48, m_session8_49, m_session8_50,
  m_session8_51, m_session8_52, m_session8_53, m_session8_54, m_session8_55, m_session8_56,
  m_session8__1, m_session8__2,
  m_session9_1, m_session9_2,
  footEmail, arrow,

  head_logo, foot_logo, close, pushIcon,
  session1_image_1, session1_icon_1, session1_icon_2, session1_icon_3,
  session2_image_1, session2_image_2, session2_image_3, session2_image_4, session2_image_5, session2_image_6, session2_image_7, session2_image_8,
  session3_image_1, session3_image_2, session3_image_3, session3_image_4,
  session4_image_3, session4_image_4, session4_icon_1, session4_icon_2, session4_icon_3,
  session7_image_1,
  BackWhite, LogoWhite, SelectIcon, Token, Network, SelectTag,
  headLogo, medium, twitter, email, lang,
  session9_icon_1, session9_icon_2, session9_icon_3, session9_icon_4, session9_icon_5, session9_icon_6, session9_icon_7, session9_icon_8, session9_icon_9,
  session9_icon_10, session9_icon_11, session9_icon_12, session9_icon_13, session9_icon_14, session9_icon_15, session9_icon_16, session9_icon_17,session9_icon_18, session9_icon_19,
  session9_icon_20, session9_icon_21, session9_icon_22, session9_icon_23, session9_icon_24, session9_icon_25, session9_icon_26, session9_icon_27, session9_icon_28, session9_icon_29,
  session9_icon_30, session9_icon_31, session9_icon_32, session9_icon_33, session9_icon_34, session9_icon_35, session9_icon_36, session9_icon_37, session9_icon_38, session9_icon_39,
  session9_icon_40, session9_icon_41, session9_icon_42, session9_icon_43, session9_icon_44, session9_icon_45, session9_icon_46, session9_icon_47, session9_icon_48, session9_icon_49
}

export default IMAGE
